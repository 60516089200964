var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.visible
    ? _c(
        "CommonDialog",
        {
          attrs: {
            width: "60%",
            dialogTitle: _vm.$t(
              "cip.plat.workflow.components.userSelect.title.indexHeadTitle"
            ),
          },
          on: { cancel: _vm.handleClose, confirm: _vm.handleConfirm },
        },
        [
          _vm.visible
            ? _c("avue-crud", {
                ref: "crud",
                attrs: {
                  option: _vm.option,
                  "table-loading": _vm.loading,
                  data: _vm.data,
                  page: _vm.page,
                },
                on: {
                  "update:page": function ($event) {
                    _vm.page = $event
                  },
                  "search-change": _vm.searchChange,
                  "search-reset": _vm.searchReset,
                  "selection-change": function ($event) {
                    _vm.selectionList = $event
                  },
                  "current-change": function ($event) {
                    _vm.page.currentPage = $event
                  },
                  "size-change": function ($event) {
                    _vm.page.pageSize = $event
                  },
                  "row-click": _vm.rowClick,
                  "on-load": _vm.onLoad,
                },
                scopedSlots: _vm._u(
                  [
                    _vm.checkType == "radio"
                      ? {
                          key: "radio",
                          fn: function ({ row }) {
                            return [
                              _c(
                                "el-radio",
                                {
                                  attrs: { label: row.id },
                                  model: {
                                    value: _vm.form.radio,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "radio", $$v)
                                    },
                                    expression: "form.radio",
                                  },
                                },
                                [_c("i")]
                              ),
                            ]
                          },
                        }
                      : null,
                  ],
                  null,
                  true
                ),
                model: {
                  value: _vm.form,
                  callback: function ($$v) {
                    _vm.form = $$v
                  },
                  expression: "form",
                },
              })
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }