<template>
  <CommonDialog v-if="visible" width="60%" :dialogTitle="$t('cip.plat.workflow.components.userSelect.title.indexHeadTitle')" @cancel="handleClose" @confirm="handleConfirm">
    <avue-crud v-if="visible"
               :option="option"
               :table-loading="loading"
               :data="data"
               :page.sync="page"
               v-model="form"
               ref="crud"
               @search-change="searchChange"
               @search-reset="searchReset"
               @selection-change="selectionList=$event"
               @current-change="page.currentPage=$event"
               @size-change="page.pageSize=$event"
               @row-click="rowClick"
               @on-load="onLoad">
      <template v-if="checkType == 'radio'"
                #radio="{row}">
        <el-radio v-model="form.radio"
                  :label="row.id"><i></i></el-radio>
      </template>
    </avue-crud>
  </CommonDialog>
</template>
<script>
import { getUser } from "@/api/system/user"
import CommonDialog from "@/components/CommonDialog"
export default {
  components: {CommonDialog},
  props: {
    defaultChecked: String,
    userUrl: {
      type: String,
      default: () => {
        return '/api/sinoma-user/search/user'
      }
    },
    customOption: Object,
    checkType: {
      type: String,
      default: () => {
        return 'radio'
      }
    }
  },
  watch: {
    checkType: {
      handler(val) {
        if (val == 'radio') {
          this.$set(this.option, 'selection', false)
          this.findObject(this.option.column, 'radio').hide = false
        } else {
          this.$set(this.option, 'selection', true)
          this.findObject(this.option.column, 'radio').hide = true
        }
      },
      immediate: true
    }
  },
  computed: {
    ids() {
      let ids = new Set()
      this.selectionList.forEach(ele => {
        ids.add(ele.id)
      })
      return Array.from(ids).join(",")
    },
    names() {
      let names = new Set()
      this.selectionList.forEach(ele => {
        names.add(ele.realName)
      })
      return Array.from(names).join(",")
    }
  },
  data() {
    return {
      isInit: false,
      visible: false,
      form: {},
      query: {},
      loading: false,
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      data: [],
      props: {
        id: 'id',
        name: 'realName',
        records: 'data.data.records',
        total: 'data.data.total',
      },
      option: {
        size: 'medium',
        searchSize: 'mini',
        align: 'center',
        menu: false,
        addBtn: false,
        header: false,
        border: true,
        tip: false,
        reserveSelection: true,
        highlightCurrentRow: true,
        gutter: 5,
        searchMenuSpan: 6,
        selection: true,
        column: [{
          label: '',
          prop: 'radio',
          type: 'radio',
          width: 55,
          hide: true
        }, {
          label: this.$t('cip.plat.workflow.components.userSelect.field.avatar'),
          prop: 'avatar',
          type: 'upload',
          width: 90
        }, {
          label: this.$t('cip.plat.workflow.components.userSelect.field.name'),
          prop: 'realName',
          overHidden: true,
          search: true
        }, {
          label: this.$t('cip.plat.workflow.components.userSelect.field.deptName'),
          prop: 'deptName',
          overHidden: true,
          search: true
        }, {
          label: this.$t('cip.plat.workflow.components.userSelect.field.postName'),
          prop: 'postName',
          overHidden: true,
          search: true
        }]
      }
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      if (!this.isInit) {
        if (this.customOption) {
          const { column, userProps } = this.customOption
          if (column) this.$set(this.option, 'column', column)
          if (userProps) this.$set(this, 'props', userProps)
        }
        this.isInit = true
      }
    },
    handleConfirm() {
      if (this.selectionList.length === 0) {
        this.$message.warning(this.$t('cip.cmn.msg.warning.selectWarning'))
        return
      }
      this.$emit('onConfirm', this.ids, this.names)
      this.handleClose()
    },
    handleClose(done) {
      // this.selectionClear()
      this.visible = false
      if (done && typeof done == 'function') done()
    },
    searchReset() {
      this.query = {}
      this.onLoad(this.page)
    },
    searchChange(params, done) {
      this.query = params
      this.page.currentPage = 1
      this.onLoad(this.page, params)
      done()
    },
    selectionClear() {
      this.selectionList = []
      if (this.$refs.crud) this.$refs.crud.toggleSelection()
    },
    rowClick(row) {
      if (this.checkType == 'radio') {
        this.selectionList = [row]
        this.$set(this.form, 'radio', row.id)
      } else this.$refs.crud.toggleSelection([row])
    },
    async changeDefaultChecked() {
      if (!this.defaultChecked) return
      let defaultChecked = this.defaultChecked

      if (this.checkType == 'checkbox') {
        // this.selectionClear()
        const checks = defaultChecked.split(",")
        if (checks.length > 0) {
          setTimeout(() => {
            checks.forEach(async (c) => {
              let row = this.data.find(d => d.id == c) // 当前页查找
              if (!row) {
                row = this.selectionList.find(d => d.id == c) // 勾选列表查找
                if (!row) {
                  let res = await getUser(c) // 接口查找
                  if (res.data.data) row = res.data.data
                }
              }
              if (row && this.$refs.crud) this.$refs.crud.toggleRowSelection(row, true)
            })
          }, 500);
        }
      } else {
        let row = this.data.find(d => d.id == defaultChecked)
        if (!row) {
          let res = await getUser(defaultChecked)
          if (res.data.data) row = res.data.data
        }

        if (row) {
          this.selectionList = [row]
          this.$set(this.form, 'radio', defaultChecked)
        } else {
          this.selectionList = []
          this.$set(this.form, 'radio', '')
        }
      }
    },
    onLoad(page, params = {}) {
      this.loading = true;
      const param = {
        current: page.currentPage,
        size: page.pageSize,
        ...Object.assign(params, this.query)
      }
      this.$axios.get(this.userUrl, { params: param }).then(res => {
        this.page.total = this.getAsVal(res, this.props.total)
        this.data = this.getAsVal(res, this.props.records) || []
        this.loading = false

        this.changeDefaultChecked()
      })
    },
    getAsVal(obj, bind = '') {
      let result = this.deepClone(obj)
      if (this.validatenull(bind)) return result
      bind.split('.').forEach(ele => {
        if (!this.validatenull(result[ele])) {
          result = result[ele]
        } else {
          result = ''
        }
      });
      return result
    }
  }
}
</script>
<style lang="scss" scoped>
.wf-dialog {
  display: flex;
  flex-direction: column;
  margin: 0 !important;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -40%);
  max-height: calc(100% - 30px);
  max-width: calc(100% - 30px);
  .el-dialog__body {
    flex: 1;
    overflow: auto;
  }
}

::v-deep .el-table .cell {
  line-height: 32px !important;
}

// 流程详情不随主题色改变
::v-deep .el-button--primary {
  color: #FFF;
  background-color: #409EFF;
  border-color: #409EFF;
}

::v-deep .el-button:hover {
  color: #409EFF;
  border-color: #c6e2ff;
  background-color: #ecf5ff;
}

::v-deep .el-input__inner:focus {
  border-color: #409EFF;
}

::v-deep .el-checkbox__inner:hover {
  border-color: #409EFF;
}

::v-deep .el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #409EFF;
  border-color: #409EFF;
}

::v-deep .el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #409EFF;
  border-color: #409EFF;
}

::v-deep .el-checkbox__input.is-focus .el-checkbox__inner {
  border-color: #409EFF;
}

::v-deep .el-pagination.is-background .el-pager li:not(.disabled):hover {
  color: #409EFF;
}

::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
  color: #FFFFFF;
  background-color: #409EFF;
}

::v-deep .el-loading-spinner .path {
  stroke: #409EFF;
}

::v-deep .el-select .el-input.is-focus .el-input__inner {
  border-color: #409EFF;
}

::v-deep .el-pagination__sizes .el-input .el-input__inner:hover {
  border-color: #409EFF;
}
</style>
<style>
.el-select-dropdown__item.selected {
  color: #409EFF !important;
}

.el-dialog__headerbtn:hover .el-dialog__close {
  color: #409EFF !important;
}
</style>
